import React from 'react'
import './index.scss'

const Notice = () => {
    const notice1 = 'We are closing for the season this April 14th, 2024. We will be back again next winter. Thank you for your support and see you again!';
  return (
    <div className='notice'>
        <div className="notice__container">
            {notice1 && <p>{notice1}</p>}
        </div>
    </div>
  )
}

export default Notice
