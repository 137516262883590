import React, { Suspense, useEffect, useState } from "react";
import "./App.scss";
import About from "./components/about/About";
import Footer from "./components/footer/Footer";
import Hero from "./components/hero/Hero";
import Navbar from "./components/navbar/Navbar";
import "./fonts/SaiGonClassique.ttf";
import MobileGallery from "./components/gallery/MobileGallery";
import Notice from './components/notice';

const Gallery = React.lazy(() => import("./components/gallery/Gallery"));
const Menu = React.lazy(() => import("./components/menu/Menu"));

function App() {
  // State to track whether the view is mobile
  const [isMobile, setIsMobile] = useState(false);

  // Effect hook to add event listener for resize event
  useEffect(() => {
    const checkIfMobile = () => {
      const match = window.matchMedia("(max-width: 1023px)").matches;
      setIsMobile(match);
    };

    // Check on mount (in case the user starts on mobile)
    checkIfMobile();

    // Add event listener
    window.addEventListener("resize", checkIfMobile);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", checkIfMobile);
  }, []);

  return (
    <div className="App">
      <Notice />
      <Navbar />
      <Hero />
      <Suspense fallback={<div>Loading About...</div>}>
        <About />
      </Suspense>
      <Suspense fallback={<div>Loading Menu...</div>}>
        <Menu />
      </Suspense>
      <MobileGallery />
      <Footer />
    </div>
  );
}

export default App;
