import React from "react";
import "./Footer.scss";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logoYellow from '../../assets/images/logo-yellow.png'

const theme = createTheme({
  palette: {
    primary: {
      main: '#AEC3D8',
    },
  },
});

const Footer = () => {
  return (
    <ThemeProvider theme={theme}>
      <div className="footer" id='contact'>
        <div className="container">
          <div className="top">
            <div className="left">
              <h3>Information</h3>
              <span><a href="https://maps.app.goo.gl/jDVNXLnWwrpUKLvt9" target="_blank">Address: 1009 Clan MacKay Rd, Dorset, ON P0A 1E0</a></span>
              <span><a href="mailto:saigonphoandmore@gmail.com">Email: saigonphoandmore@gmail.com</a></span>
              <span><a href='tel:12498771359'>Phone: (249) 877 1359</a></span>
            </div>
            <div className="center">
              <a href="/">
                <img src={logoYellow} alt="saigon-pho-logo" />
              </a>              
              <div className="icons">
                <a href="https://www.facebook.com/profile.php?id=61554224881543" target="_blank">
                  <FacebookIcon fontSize="large" color="primary" />
                </a>
                <a href="https://www.instagram.com/saigonphoandmore/" target="_blank">
                  <InstagramIcon fontSize="large" color="primary" />
                </a>
              </div>
            </div>
            <div className="right">
              <h3>Working Hours</h3>
              <span>Friday - Sunday: 12:00 pm - 07:00 pm</span>
            </div>
          </div>
          <div className="bottom">
            <span>
              © 2023 All Rights Reserved. Saigon Pho & More. Developed by{" "}
              <a href="www.waynebuildsweb.ca" style={{ cursor: "pointer" }}>
                WayneBuildsWeb.ca
              </a>
            </span>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Footer;
