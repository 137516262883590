export const images = [
    {
      src: "https://saigonpho.b-cdn.net/gallery-1.JPG",
      title: "Crispy Springroll - Chả Giò",
      desc: "<p>The aroma of simmering broth and the sizzle of stir-fried noodle welcome you to our family's Vietnamese kitchen. Here, every crispy spring roll tells a story of tradition and fresh flavors. We use the finest ingredients – juicy pork, delicate taro, sweet yam, or vibrant vegetables – all seasoned with a touch of generational knowledge. Each roll, wrapped in delicate spring roll pastry and fried to golden perfection, promises a satisfying crunch and an explosion of flavor.</p><p>Whether you choose the classic pork and taro or the colorful vegetarian delight, our spring rolls are a testament to the vibrant flavors of Vietnam and the joy of food made with love.</p>",
    },
    {
      src: "https://saigonpho.b-cdn.net/gallery-2.JPG",
      title: "Bánh Mì - Vietnamese Sandwich",
      desc: "<p>The air crackles with the scent of marinated pork sizzling on the grill. Sweetness mingles with savory aromas, a promise of something special. This is where the Vietnamese grilled pork banh mi begins, a story of simple ingredients elevated by tradition.</p><p>A fresh baguette, its crust crisp, awaits a swipe of homemade butter and the tangy crunch of pickled carrots and radish. Then comes the star: juicy grilled pork infused with lemongrass and garlic, layered with Mia's special sauce.</p><p>Each bite is a delightful contrast –  airy bread, tender pork, vibrant pickles. The butter's richness and Mia's sauce add a touch of mystery and depth. This is a celebration of pure flavors, a testament to how a few perfect ingredients create a symphony of taste.</p>",
    },
    {
      src: "https://saigonpho.b-cdn.net/gallery-3.JPG",
      title: "Stirfried Noodle - Mì Xào",
      desc: "<p>A symphony of flavors awaits in our sizzling stir-fried noodles.  The wok dances with vibrant vegetables – crisp cabbage, crunchy bean sprouts, sweet carrots, and pops of colorful bell peppers.  Choose your favorite: tender, savory beef, juicy bites of chicken, or a vibrant vegetarian medley.  Aromatic herbs like cilantro and spring onions add a final burst of freshness. It's all tossed in a savory sauce that brings every element together.  This isn't just a meal, it's a celebration of flavor and texture!</p>",
    },
    {
      src: "https://saigonpho.b-cdn.net/gallery-4-replace.JPG",
      title: "Stirfried Pho - Rice Noodle",
      desc: "<p>The wok sizzles, flames dancing around it as garlic and ginger fill the air.  Tender rice noodles tumble in, absorbing the savory scents.  Crisp cabbage, crunchy bean sprouts, and vibrant ribbons of carrot add bursts of color.  Your choice awaits – savory slices of beef, juicy chicken, or a colorful vegetarian medley.</p><p>A splash of sauce, a vibrant sweet and sour blend, coats everything with a glistening sheen.  The noodles soak up the tangy sweetness, a perfect contrast to the savory meat or the fresh vegetables.  A sprinkle of cilantro and spring onions adds a final burst of freshness. This isn't just stir-fry, it's an explosion of flavors, a familiar taste adventure with an exciting twist.</p>",
    },
    {
      src: "https://saigonpho.b-cdn.net/gallery-5-replace.JPG",
      title: "Pho [fə̃ː] - Beef Noodle Soup",
      desc: "<p>A sense of excitement hummed in Mia's fingers as she measured the star anise and cinnamon. Her mother's pho recipe, the cornerstone of her successful Vietnamese restaurant, now filled Mia's Canadian kitchen with its warmth. Though years had passed, Mia's innate culinary sense, a gift passed down through generations, hadn't dulled. She moved with practiced ease, the rhythm of simmering broth and the artful arrangement of ingredients feeling as natural as breathing.</p><p>Each element was a testament to her attention to detail. The paper-thin slices of beef, the vibrant burst of fresh cilantro and basil, the tangle of bean sprouts – they were a canvas for tradition, infused with her own newfound flair.\"The secret,\" her mother's voice echoed in her memory, \"Is patience and love.\" Mia smiled, pouring those very ingredients into every ladleful of broth.</p><p>This wasn't just a meal; it was a testament to her heritage, a rediscovery of her roots. With every steaming bowl, Mia shared the flavors of her past, the legacy of her mother's recipe, and a vibrant touch of her own undeniable culinary talent.</p>",
    },
  ];