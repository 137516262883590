import React, { useEffect, useState } from "react";
import "./Hero.scss";
import { motion } from "framer-motion";
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

const FullscreenSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [revealAnimation, setRevealAnimation] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(true);

  const images = [
    [
      'https://saigonpho.b-cdn.net/hero-image-2-update.JPG',
      "Authenticity",
      "Made from time-honored family recipes passed down through generations",
    ],
    [
      'https://saigonpho.b-cdn.net/hero-image-1.JPG',
      "Bursting Flavors",
      "Fresh herbs, spices, and meats create a bursting bowl of Phở with authentic Vietnamese flavors.",
    ],
    [
      'https://saigonpho.b-cdn.net/hero-image-3-update.JPG',
      "A Culinary Journey",
      "Embark on a culinary journey to Vietnam with each steaming bowl of Phở",
    ],
  ];
  useEffect(() => {
    setTimeout(() => {
      setHasLoaded(false);
    }, 1000);
  }, []);

  const nextSlide = () => {
    setRevealAnimation(true); // Toggle the text animation

    // Add a delay before changing the slide
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 1000);
    setTimeout(() => {
      setRevealAnimation(false); // Turn off the text animation
    }, 2000); // Adjust the delay duration as needed (2000ms = 2 seconds)
  };

  const prevSlide = () => {
    setRevealAnimation(true); // Toggle the text animation

    // Add a delay before changing the slide
    setTimeout(() => {
      setCurrentIndex(
        (prevIndex) => (prevIndex - 1 + images.length) % images.length
      );
    }, 1000);
    setTimeout(() => {
      setRevealAnimation(false); // Turn off the text animation
    }, 2000); // Adjust the delay duration as needed (2000ms = 2 seconds)
  };

  return (
    <div className="fullscreen-slider">
      <div className="navigation">
        <div className="slider-button prev" onClick={prevSlide}><ArrowBackIosOutlinedIcon id='nav-btn' style={{fontSize:'50px', color: 'white', position:'absolute', top: '52%', left: '20%'}} /></div>
        <div className="slider-button next" onClick={nextSlide}><ArrowForwardIosOutlinedIcon id='nav-btn' style={{fontSize:'50px', color: 'white', position:'absolute', top: '52%', right: '20%'}} /></div>
      </div>
      <div className="img-container">
        <motion.img
          key={currentIndex} //This key force re-rendering
          src={images[currentIndex][0]}
          alt={`Slide ${currentIndex + 1}`}
          initial={{ opacity: 0, scale: 1.4 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 1, scale: 1 }} // Exit animation
          transition={{ duration: 0.5 }} // Adjust the duration as needed
        />
        <div
          className={`text-container ${
            revealAnimation ? "animate-reveal" : ""
          } ${hasLoaded ? "initial-animation" : ""}`}
        >
          <div className="text">
            <h1>{images[currentIndex][1]}</h1>
            <span>{images[currentIndex][2]}</span>
          </div>            
        </div>
        <div className="text-overlay"></div>     
      </div>
    </div>
  );
};

export default FullscreenSlider;
