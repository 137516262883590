import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import "./Navbar.scss";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const Navbar = () => {
  const [show, setShow] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to track menu visibility
  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (typeof window !== "undefined") {
      // Only update 'show' if the menu is not open
      if (!isMenuOpen) {
        setShow(window.scrollY < lastScrollY || window.scrollY < 50);
      }
      setLastScrollY(window.scrollY);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", controlNavbar);
      return () => window.removeEventListener("scroll", controlNavbar);
    }
  }, [lastScrollY, isMenuOpen]);

  return (
    <div className={`navbar ${show ? "visible" : "hidden"}`}>
      <div className="container desktop">
        <Link to="main" className="logo">
          <img src={'https://saigonpho.b-cdn.net/logo-color.png'} alt="saigon-pho-logo" />
        </Link>
        <ul className="nav-menu">
          <li>
            <Link to="main" classID="active">
              Home
            </Link>
          </li>
          <li>
            <Link to="about" classID="active">
              About Us
            </Link>
          </li>
          <li>
            <Link to="menu" classID="active">
              Our Menu
            </Link>
          </li>
          <li>
            <Link to="gallery" classID="active">
              Gallery
            </Link>
          </li>
          <li>
            <a href='tel:12498771359'>
              Order Takeout
            </a>
          </li>
        </ul>
      </div>
      <div className="container mobile">
        <a href="/">
          <img src={'https://saigonpho.b-cdn.net/logo-color.png'} alt="saigon-pho-logo" />
        </a>
        <div className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
          <MenuOutlinedIcon />
        </div>
        <div className={`mobile-wrapper ${isMenuOpen ? 'reveal' : ''}`}>
        {isMenuOpen ? (
          <>
            <div className='close' onClick={() => setIsMenuOpen(!isMenuOpen)}>
              <CancelOutlinedIcon />
            </div>
            <ul className="menu-nav">
              <li>
                <Link to="main" classID="active">
                  <span onClick={() => setIsMenuOpen(!isMenuOpen)}>Home</span>
                </Link>
              </li>
              <li>
                <Link to="about" classID="active">
                  <span onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    About Us
                  </span>
                </Link>
              </li>
              <li>
                <Link to="menu" classID="active">
                  <span onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    Our Menu
                  </span>
                </Link>
              </li>
              <li>
                <Link to="gallery" classID="active">
                  <span onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    Gallery
                  </span>
                </Link>
              </li>
              <li>
                <Link to="contact" classID="active">
                  <span onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    Contact Us
                  </span>
                </Link>
              </li>
            </ul>
          </>
        ) : (
          <></>
        )}
        </div>
        
      </div>
    </div>
  );
};

export default Navbar;
